<template>
    <div class="mainMindSet">
      <div class="upperMindSet">
        <div class="filter-wrapper">
          <FiltersComponent
            :allowFilter="{
              team: true,
              tag: true
            }"
            :max="mindsetMaxSelection"
            :multiple="false"
            :modelValue="selections"
            @update:modelValue="saveSelection"
          />
          <frontend-gated-component value="theOMindsetInYourCompany" />
        </div>
      </div>
      <MessageBoxComponent v-if="selections.length > 0"
        :isWarning="false" :text="$t('mngDashboardComponent.clickToCompareCompany')">
       <div class="checkboxContainer">
          <input
            type="checkbox"
            id="compareToCompanyId"
            class="switch-input switchCase"
            :value="isCompareToCompany"
            @input="compareToCompany"
          />
          <label for="compareToCompanyId" class="switch-label"></label>
        </div>
      </MessageBoxComponent>
      <MessageBoxComponent is-warning :text="mindsetWarning" />
      <div class="lowerMindSet">
        <div class="leftMindSetContainer">
          <div class="topMindSetChartContainer">
            <h1>{{ $t('mngDashboardComponent.theOMindsetInYourCompany') }}</h1>
            <select v-model="mindSetSelectFilter" @change="mindSetOverAllFunction()">
              <option v-for="item in mindsetPeriod" :key="item?.identifier"
                :value="item?.identifier">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="mindSetChartContainer"
          tabindex="0">
            <OMindSetCompanyChart ref="mindSetCompany" />
          </div>
          <frontend-gated-component value="theOMindsetInYourCompany" />
        </div>
        <div class="rightMindSetContainer" v-if="mindsetTipText?.length > 0">
          <div class="avgChartContainer"
            style="align-items: normal; height: 100%; justify-content: flex-start;">
            <template v-for="(tip,i) in mindsetTipText" :key="i">
              <div class="OMindsetDevelopmentTipGroup" v-if="tip?.length > 0">
                <OMindsetDevelopmentTip :style="`width: ${100 / tip.length}%`"
                 v-for="t in tip" :key="t?.title" :data="t"/>
              </div>
              <OMindsetDevelopmentTip v-else :data="tip"/>
            </template>
          </div>
          <frontend-gated-component value="theOMindsetInYourCompany" />
        </div>
      </div>
    </div>
  </template>
<script>
import '../managementDashboard.css';
import OMindSetCompanyChart from '@/components/Home/ManagementDashboard/OmindsetComponent/OmindsetChartComponent.vue';
import OMindsetDevelopmentTip from '@/components/Home/ManagementDashboard/OmindsetComponent/OmindsetDevelopmentTipComponent.vue';
import FiltersComponent from '@/components/Home/ManagementDashboard/common/FiltersComponent.vue';
import MessageBoxComponent from '@/components/Home/ManagementDashboard/common/MessageBoxComponent.vue';
import FrontendGatedComponent from '@/components/Home/Shared/FrontendGated/FrontendGatedComponent.vue';
import { generateListColors, addMissingColor } from '@/helpers/graphPlotHelper';
import FeatureHelper from '@/helpers/FeatureHelper';

export default {
  name: 'OmindsetComponent',
  data() {
    return {
      currentSelections: [],
      mindSetSelectFilter: 'AllTime',
      showAverageText: true,
      isCompareToCompany: false,
      selectionCompareToCompany: {
        name: {
          en: 'Whole Organization',
          de: 'Ganzes Unternehmen',
        },
        selection: {},
        wholeCompany: true,
        color: '#ff0000',
      },
      subFeature: {
        name: 'theOMindsetInYourCompany',
      }
    };
  },
  components: {
    MessageBoxComponent,
    OMindsetDevelopmentTip,
    OMindSetCompanyChart,
    FiltersComponent,
    FrontendGatedComponent,
  },
  computed: {
    selections: {
      get() {
        return this.$store.getters.getSelections;
      },
      set(value) {
        this.$store.commit('setSelections', value);
      },
    },
    oMindSetCompanyData() {
      return this.$store.getters.getOmindset?.oMindSetCompanyData;
    },
    mindsetTipText() {
      return this.$store.getters.getOmindset?.mindsetTipText;
    },
    mindsetPeriod() {
      return this.$store.getters.getOmindset?.mindsetPeriod;
    },
    mindsetWarning() {
      return this.$store.getters.getOmindset?.warning;
    },
    mindsetMaxSelection() {
      return this.$store.getters.getOmindset?.maxSelection;
    },
    featureStatus() {
      return FeatureHelper.featureStatus(this.subFeature.name);
    }
  },
  methods: {
    // --------- Main Function to call the api on every filters change ---------------- //
    async mindSetOverAllFunction() {
      try {
        const selections = this.prepareSelection();
        await this.$store.dispatch('fetchInitialMindSetCompanyData', {
          selections,
          mindsetPeriodType: this.mindSetSelectFilter,
          name: this.subFeature.name,
        });
        this.$refs.mindSetCompany?.OMindSetCompanyCharts(this.oMindSetCompanyData);
      } catch (error) {
        console.error(error);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
      }
    },
    compareToCompany() {
      this.isCompareToCompany = !this.isCompareToCompany;
      this.mindSetOverAllFunction();
    },

    saveSelection(selection, flag = false) {
      const selectionHasColors = this.currentSelections.filter((s) => s.color);
      const selections = selection.map((s) => {
        const matchSelection = selectionHasColors.find((z) => z.name.en === s.name.en);
        if (!matchSelection) return s;
        return {
          ...s,
          color: matchSelection.color,
        };
      });
      this.currentSelections = [...selections];
      if (flag === false) this.selections = [...selections];
    },
    prepareSelection() {
      const listColors = generateListColors(
        this.isCompareToCompany ? this.currentSelections.length + 1 : this.currentSelections.length,
      );
      const ableSelectionColor = this.currentSelections.filter((s) => s.color).map((s) => s.color);
      addMissingColor(listColors, ableSelectionColor);
      this.currentSelections = this.currentSelections.map((s, i) => ({
        ...s,
        color: ableSelectionColor[i],
      }));
      if (this.isCompareToCompany && this.currentSelections.length > 0) {
        return [
          ...this.currentSelections,
          this.selectionCompareToCompany,
        ];
        // eslint-disable-next-line no-else-return
      } else {
        this.isCompareToCompany = false;
      }
      return this.currentSelections;
    },
  },
  watch: {
    selections(value) {
      this.saveSelection(value, true);
      this.mindSetOverAllFunction();
    },
    featureStatus() {
      this.mindSetOverAllFunction();
    }
  },
  mounted() {
    this.mindSetOverAllFunction();
  },
};
</script>
