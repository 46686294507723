<template>
  <div class="feedback-button" @click="displayFeedbackForm">
    <font-awesome-icon icon="fa-comments"/>
    <label>{{ $t('ProvideFeedbackComponent.feedback') }}</label>
  </div>
</template>
<script>
export default {
  name: 'ProvideFeedbackComponent',
  data() {
    return {
      user: {},
    };
  },
  methods: {
    async displayFeedbackForm() {
      try {
        await this.$store.dispatch('getUserInfo');
        this.user = this.$store.getters.getUserDetails;
      } catch (e) {
        this.user.userId = this.$store.getters.getUserId;
      } finally {
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'provideFeedback',
          notification: {
            content: {
              name: this.user.name || '',
              firstName: this.user.firstName || '',
              userId: this.user.userId || '',
            },
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.feedback-button {
  position: fixed;
  width: fit-content;
  padding: 1rem;
  bottom: 65px;
  right: 30px;
  color: var(--color-orange);
  background-color: var(--color-white);
  border: 1px solid;
  border-radius: 50px;
  border-color: var(--color-orange);
  z-index: 25 !important;
}

.feedback-button:hover {
    box-shadow: 0 0 3px 1px var(--color-orange);
    cursor: pointer;
}

label {
    padding-left: 5px;
    cursor: pointer;
}
</style>
