<template>
  <div class="parent-container">
    <div class="left-column">
      <div class="align-items-start links-parent quick-access" id="links_container">
        <QuickAccessLinksComponent v-for="l in links"
                         :url = "l?.link"
                         :icon = "l?.icon"
                         :key="l?._id"/>
      </div>
    </div>
    <div class="right-column">
      <div v-if="weekGoals.length > 0" class="week-goals-container">
        <h1>{{ $t('HomeComponent.yourWeekgoals') }}</h1>
        <div class="week-goals-container-dummy week-goals-wrapper" id="div_weekgoals">
          <WeekgoalComponent v-for="goal in weekGoals"
                         :done-tasks="goal.have"
                         :id="goal.critereaDescriptionId"
                         :reached="goal.reached"
                         :total-tasks="goal.should"
                         :representationProperties="goal.representationProperties"
                         :key="goal"/>
          <TravelBuddyCardComponent
            v-if="travelBuddySystemActivated === true && getTravelBuddyEnabled"
          />
        </div>
      </div>

      <div class="team-container">
        <h1>{{ $t('HomeComponent.teamspace') }}</h1>
        <div
          v-if="getTeamspaceEnabled"
          class="teamspace-container-dummy"
          id="div_teamspace"
        >
          <member-component v-for="member in teamspace" :key="member._id" :member="member"/>
          <frontend-gated-component
            value="teamspace"
          />
        </div>
      </div>
      <div class="task-container">
        <h1>{{ $t('HomeComponent.myTasks') }}</h1>
        <div
          v-if="getMyTasksEnabled"
          class="task-dummy"
          id="div_taskcontainer"
        >
          <task-component v-for="task in assessments" :key="task._id" :task="task"/>
          <frontend-gated-component value="myTasks" />
        </div>
      </div>
      <div class="task-container">
        <h1>{{ $t('HomeComponent.myPersonalGoals') }}</h1>
        <div
          v-if="personalGoals.length !== 0 && getMyPersonalGoalsEnabled"
          class="task-dummy"
          id="div_taskcontainer"
        >
          <goal-component v-for="goal in personalGoals" :key="goal._id" :goal="goal"/>
          <frontend-gated-component
            value="personalGoals"
            :should-gate="getMyPersonalGoalsGated"
          />
        </div>
        <div
          v-if="(!personalGoals || personalGoals.length === 0) && getMyPersonalGoalsEnabled"
          class="task-dummy"
        >
          <no-personal-goals-component class="no-personal-goals"></no-personal-goals-component>
          <frontend-gated-component
            value="personalGoals"
            :should-gate="getMyPersonalGoalsGated"
          />
        </div>
      </div>
    </div>
    <ProvideFeedbackComponent></ProvideFeedbackComponent>
  </div>
 </template>

<script>
import MemberComponent from '@/components/Home/HomeComponent/MemberComponent.vue';
import TaskComponent from '@/components/Home/HomeComponent/TaskComponent.vue';
import GoalComponent from '@/components/Home/HomeComponent/GoalComponent.vue';
import * as push from '@/helpers/webpush/push';
import WeekgoalComponent from '@/components/Home/HomeComponent/WeekgoalComponent.vue';
import QuickAccessLinksComponent
  from '@/components/Home/HomeComponent/QuickAccessLinksComponent.vue';
import NetworkAuth from '@/helpers/networking/NetworkAuth';
import store from '@/store';
import NoPersonalGoalsComponent
  from '@/components/Home/PersonalGoalsComponent/NoPersonalGoalsComponent.vue';
import TravelBuddyCardComponent from '@/components/Home/HomeComponent/TravelBuddyCardComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';
import FrontendGatedComponent from '../Shared/FrontendGated/FrontendGatedComponent.vue';
import FeatureHelper from '@/helpers/FeatureHelper';
import FeatureRoute from '@/utils/features';

export default {
  name: 'HomeComponent',
  data() {
    return {
      teamspace: this.$store.state.content.teamSpace,
      tasksGraber: '',
      overlayGraber: '',
      permissionsGraber: '',
      userInfoGraber: '',
      devTipGraber: '',
      travelBuddySystemActivated: false,
    };
  },
  components: {
    TravelBuddyCardComponent,
    WeekgoalComponent,
    TaskComponent,
    MemberComponent,
    QuickAccessLinksComponent,
    GoalComponent,
    NoPersonalGoalsComponent,
    ProvideFeedbackComponent,
    FrontendGatedComponent,
  },
  computed: {
    assessments() {
      return this.$store.state.content.assessments;
    },
    weekGoals() {
      return this.$store.getters.getWeekGoals;
    },
    links() {
      return this.$store.getters.getQuickAccessLinks;
    },
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
    personalGoals() {
      const personalGoalsArray = this.$store.getters.getPersonalGoals;
      if (!personalGoalsArray || personalGoalsArray.length === 0) {
        return false;
      }
      const activeGoals = personalGoalsArray.filter((goal) => goal.status === 'active');
      this.$store.commit('setActiveGoalsCount', activeGoals.length);
      return activeGoals;
    },
    getTeamspaceEnabled() {
      return this.featureStatus('teamspace') !== -1;
    },
    getTravelBuddyEnabled() {
      return this.featureStatus('travelBuddy') !== -1;
    },
    getMyTasksEnabled() {
      return this.featureStatus('myTasks') !== -1;
    },
    getMyPersonalGoalsEnabled() {
      return (
        this.getPermissions?.admin
        || !FeatureHelper.IsAllSubFeatureDeactivated(FeatureRoute.personalGoals)
      );
    },
    getMyPersonalGoalsGated() {
      if (this.getPermissions?.admin) return false;
      return FeatureHelper.getGatedStatus('personalGoals') === 'gated';
    },
  },
  methods: {
    async getTeamspace() {
      await this.$store.dispatch('getTeamspace');
    },
    async getAssessmentsOverview() {
      await this.$store.dispatch('getAssessmentsOverview');
    },
    async getOverlayNotes() {
      this.$store.dispatch('getOverlayNotes');
    },
    async getQuickAccessLinks() {
      await this.$store.dispatch('getQuickAccessLinks');
    },
    async getPersonalGoals() {
      await this.$store.dispatch('getPersonalGoals');
    },
    async getTravelBuddy() {
      await this.$store.dispatch('getTravelBuddyInfo', {
        params: {
          name: 'travelBuddy',
        },
      });
    },
    async getWorkshopTags() {
      await this.$store.dispatch('getWorkshopTags');
    },
    async getNewUserDemoContent() {
      await this.$store.dispatch('getNewUserDemoContent');
    },
    featureStatus(name) {
      return FeatureHelper.featureStatus(name);
    },
  },
  watch: {
    featureStatus() {
      this.getTravelBuddy();
    },
  },
  async mounted() {
    const { data } = await NetworkAuth.checkIfUserLoggedIn();
    store.commit('updatePermissionFlags', data.permissionFlags);
    this.getQuickAccessLinks();
    this.getPersonalGoals();
    this.getTravelBuddy();
    this.getWorkshopTags();
    this.getNewUserDemoContent();

    await this.$store.dispatch('getUserInfo');
    this.$i18n.locale = this.$store.state.auth.user.preferredLanguage;
    this.travelBuddySystemActivated = this.$store.state.auth.user.travelBuddySystem?.activated;
    await Promise.all([
      await this.$store.dispatch('getDevTippOverview'),
      await this.getTeamspace(),
      await this.getAssessmentsOverview(),
      await this.getOverlayNotes(),
    ]);

    this.devTipGraber = setInterval(() => {
      this.$store.dispatch('getDevTippOverview');
    }, 3000);

    this.tasksGraber = setInterval(() => {
      this.$store.dispatch('getAssessmentsOverview');
    }, 3000);

    this.permissionsGraber = setInterval(() => {
      this.$store.dispatch('updatePermissionFlags');
    }, 30000);

    this.overlayGraber = setInterval(() => {
      this.$store.dispatch('getOverlayNotes');
    }, 5000);

    this.userInfoGraber = setInterval(() => {
      this.$store.dispatch('getUserInfo');
    }, 5000);

    if (process.env.NODE_ENV !== 'test') {
      if (push.checkServiceAvailable()) {
        await push.checkSubscription();
      }
    }
  },
  unmounted() {
    this.$store.commit('removeTeamspace');
    this.$store.commit('removeAssessments');
    clearInterval(this.tasksGraber);
    clearInterval(this.permissionsGraber);
    clearInterval(this.overlayGraber);
    clearInterval(this.userInfoGraber);
    clearInterval(this.devTipGraber);
  },
};
</script>

<style>
.quick-access {
  max-width: 106px;
  margin-top: 3.5rem;
}
/*------------------- WEEKGOALS TEMPLATE -----------------------*/
.week-goals-container {
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
  overflow-y: visible;
}
.no-personal-goals {
  width: 85vw;
  margin-top: 20px;
}
.week-goals-container-dummy {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 0px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  gap: 0.3rem;
  align-content: center;
}

.week-goals-container-dummy::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/*------------------- TEAMSPACE TEMPLATE -----------------------*/
.team-container {
  user-select: none;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
}

.teamspace-container-dummy {
  position: relative;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  display: flex;
  justify-content: flex-start;
  height: 170px;
  gap: 0.3rem;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: center;
  align-content: center;
}

.teamspace-container-dummy::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.team-container .teamspace-container-dummy .member {
  margin: 0.5rem 0;
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  position: relative;
}

.member .photo {
  width: 5rem;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

.member .name {
  padding: 0 5px 5px;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: center;
}

/*------------------- TASKS TEMPLATE -----------------------*/
.task-container {
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
}

.task-container .task-dummy {
  position: relative;
  display: block;
  justify-content: space-between;
  /*height: 500px;
  overflow-y: scroll;*/
  gap: 0.3rem;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  margin-top: 15px;
}

.task-container .task-dummy .task-card {
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 0.5rem 0;
}

.task-container .task-dummy .task-card:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.task-dummy .task-card .headline {
  display: flex;
  justify-content: space-between;
}

.task-dummy .task-card .headline h2 {
  font-size: 23px;
}

.task-dummy .task-card .headline .done i {
  font-size: 2rem;
  color: var(--color-disabled);
}

.time-rubber {
  /* Date-container on task-element, change color to: var(--color-disable)*/
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: max-content;
  border-radius: 4px;
  background-color: var(--color-orange);
}

.time-rubber i {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.date {
  color: var(--color-white);
  padding: 5px;
  width: 100%;
}

.task-container, .week-goals-container, .team-container{
  max-width: 1440px;
}

/*------------------- PARENT CONTAINER -----------------------*/

.parent-container {
  width: 96vw;
  display: grid;
  grid-template-columns: 3fr 2fr;
  position: relative;
  margin-bottom: 8rem;
}

.right-column {
  width: 80vw;
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

/*==================== MEDIA QUERIES =====================*/

@media (max-width: 1440px){
  .week-goals-container-dummy{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px){
  .week-goals-container-dummy{
    grid-template-columns: 1fr;
  }
  .week-goals-container {
    margin-bottom: 180px;
  }
}
@media (max-width: 1024px) {
  h1 {
    font-size: 25px;
  }

  .team-container {
    margin-bottom: 30px;
  }

  .teamspace-container-dummy {
    height: 170px;
  }

  .member .photo {
    width: 4rem;
  }

  .member .name {
    font-size: 15px;
  }

  .task-container {
    position: relative;
  }

  .task-dummy .task-card .headline h2 {
    font-size: 19px;
  }

  .task-dummy .task-card .headline .done i {
    font-size: 1.7rem;
  }

  .task-dummy .task-card .status {
    font-size: 15px;
  }

  .date {
    padding: 1px;
  }

  .parent-container {
    width: 90vw;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    margin-bottom: 8rem;
  }

  .right-column h1 {
    font-size: 23px;
  }

  .right-column {
    position: relative;
    width: 90vw;
    margin-left: 0rem !important;
    order: 1;
  }

  .left-column {
    position: relative;
    bottom: 0%;
    margin-left: 0.7rem !important;
    margin-right: 0.7rem !important;
    order: 2;
    margin-top: 5rem !important;
  }

  .links-parent {
    display: flex;
    flex-direction: row;
    margin-left: 0rem !important;
    overflow-x: scroll;
  }

  .week-goals-container-dummy {
    justify-content: flex-start !important;
    display: grid;
  }
}

@media (min-width: 1025px) and (max-width: 1919px) {
  .parent-container {
    width: 100vw;
    display: grid;
    grid-template-columns: 2fr 2fr;
    position: relative;
    margin-bottom: 8rem;
  }

  .right-column {
    width: 85vw;
    margin-left: 0em !important;
    margin-right: 4rem !important;
  }

  .left-column {
    margin-left: 2rem !important;
  }

  .week-goals-container {
    width: 98%;
  }

  .team-container {
    width: 98%;
  }

  .task-container {
    width: 98%;
  }
}

@media (min-width: 1920px) {

  .task-container, .week-goals-container, .team-container{
    max-width: 1440px;
  }
  .quick-access {
    margin-top: 7.5rem;
  }

  .week-goals-container {
    width: 80vw;
  }

  .team-container {
    margin-top: 2rem;
    width: 80vw;
  }

  .task-container {
    margin-top: 2rem;
    width: 80vw;
  }
}

@media (max-width: 1920px) {
  .quick-access {
    margin-top: 4.5rem;
  }
}

@media screen and (max-width: 1155px) {

  .right-column {
    width: 85vw;
    margin-left: 0em !important;
    margin-right: 4rem !important;
  }
  .week-goals-container {
    width: 98%;
  }

  .team-container {
    width: 98%;
  }

  .task-container {
    width: 98%;
  }
}

@media screen and (max-width: 1024px) {
  .quick-access {
    margin-top: 21rem;
  }
  .week-goals-container {
    width: 95%;
  }

  .team-container {
    width: 90%;
  }

  .task-container {
    width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .week-goals-container-dummy {
    justify-content: center;
  }
  .right-column {
    width: 90vw;
  }
  .week-goals-container {
    margin-left: auto;
    width: 100%;
  }

  .team-container {
    margin-left: auto;
    width: 100%;
  }

  .task-container {
    margin-left: auto;
    width: 100%;
  }

  .quick-access {
    max-width: 85vw;
    margin-top: 0rem;
  }
}

</style>
