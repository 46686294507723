<template>
  <div>
    <div class="title-container">
      <management-feature-row-component
        :feature="feature"
        @onChangeFeatureAllValue="onChangeFeatureAllValue"
      />
    </div>
    <management-feature-row-component
      class="feature-row"
      v-for="subFeature in feature.subFeatures"
      :upperFeatureName="feature.name"
      :feature="subFeature"
      :key="subFeature.name"
      @onChangeFeatureValue="onChangeFeatureValue"
    />
  </div>
</template>

<script>
import ManagementFeatureRowComponent from '@/components/Home/AdminComponent/AdminSettingComponent/ManagementFeatureRowComponent.vue';

export default {
  name: 'ManagementFeatureRowsComponent',
  components: {
    ManagementFeatureRowComponent,
  },
  props: {
    feature: Object,
  },
  methods: {
    onChangeFeatureValue({ upperFeatureName, subFeatureName, value }) {
      this.$emit('onChangeFeatureValue', {
        upperFeatureName,
        subFeatureName,
        value,
      });
    },
    onChangeFeatureAllValue({ featureName, value }) {
      this.$emit('onChangeFeatureAllValue', {
        featureName,
        value,
      });
    },
  },
};
</script>

<style scoped>
.title-container {
  border-bottom: 4px solid var(--color-bisque);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.feature-title {
  font-size: 22px;
  padding-bottom: 10px;
}

.feature-row {
  padding-left: 20px;
}
</style>
