<template>
  <div class="popup-overview">
    <h3>{{ $t('DevTipItemContainerComponent.yourTips') }}</h3>
    <div class="overview-content">
      <div class="overview-container">
        <dev-tip-item-component v-for="item in getDevTippOverview" :key="item" :tip="item"/>
        <p v-if="getDevTippOverview.length === 0">
          {{ $t('DevTipItemContainerComponent.noTips') }}
        </p>
      </div>
      <div class="overview-grid3">
        <p>
          <a @click.prevent="openDevTipLibrary">
            {{ $t('DevTipItemContainerComponent.showMore') }} >
          </a>
        </p>
      </div>
      <frontend-gated-component
        value="developmentTip"
        :should-gate="getDevelopmentTipGatedStatus"
      />
    </div>
    <button class="close-btn" @click.prevent="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
  </div>
</template>

<script>
import DevTipItemComponent from '@/components/Home/Shared/DevTip/DevTipItemComponent.vue';
import FrontendGatedComponent from '../FrontendGated/FrontendGatedComponent.vue';
import FeatureHelper from '@/helpers/FeatureHelper';
import FeatureRoute from '@/utils/features';

export default {
  name: 'DevTipItemContainerComponent',
  components: { DevTipItemComponent, FrontendGatedComponent },
  computed: {
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
    getDevTippOverview() {
      return this.$store.getters.getDevTippOverview;
    },
    getDevelopmentTipEnabled() {
      return (
        this.getPermissions.admin
        || !FeatureHelper.IsAllSubFeatureDeactivated(FeatureRoute.developmentTip)
      );
    },
    getDevelopmentTipGatedStatus() {
      if (this.getPermissions.admin) return false;
      return FeatureHelper.getGatedStatus('developmentTip') === 'gated';
    },
  },
  methods: {
    openDevTipLibrary() {
      this.$store.commit('toggleShowDevTipps');
      this.$store.commit('addFrontendOverlay', {
        id: Math.random(),
        type: 'devTipLibrary',
      });
    },
    closePopup() {
      this.$store.commit('toggleShowDevTipps');
    },
  },
  mounted() {
    this.$store.dispatch('getDevTippOverview');
  },
};
</script>

<style scoped>
.popup-overview {
  font-size: 12px;
  position: fixed;
  top: 10%;
  right: 5%;
  margin-left: 5%;
  background-color: rgb(255, 255, 255);
  padding: 1em 1em 0 2em;
  border-radius: var(--card-border-radius);
  line-height: 1.5em;
  box-shadow: var(--card-box-shadow);
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  z-index: 20;
}

.close-btn {
  font-family: 'Poppins';
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: var(--card-close-btn);
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.close-btn:hover {
  color: var(--color-orange);
}

.overview-content {
  position: relative;
}

.overview-container {
  overflow-y: auto;
  max-height: 70vh;
  flex-grow: 1;
}

.popup-overview h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
}

.overview-grid3 {
  display: grid;
  place-items: center;
  justify-items: right;
  margin-bottom: 1em;
}

.overview-grid3 a {
  text-decoration: none;
  color: #999999;
  cursor: pointer;
}

</style>
