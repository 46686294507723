import NetworkAuth from '@/helpers/networking/NetworkAuth';
import axios from 'axios';
import i18n from '@/includes/i18n';
import keycloak from '../../../keycloak';

export default {
  state: {
    token: localStorage.getItem('jwt') || null,
    refresh_token: localStorage.getItem('jwt_refresh_token') || null,
    user: {
      permissionsFlags: {
        teamLeader: false,
        admin: false,
        companySuperuser: false,
      },
    },
    registerData: null,
    registerDataValid: false,
    passwordResetRequested: false,
    registrationSuccessful: false,
    newPasswordSetSuccesful: false,
    lang: localStorage.getItem('lang') || 'en',
    unprotectedTags: [],
    protectedTags: [],
    selectedTags: [],
    tagQuestonsAnswers: {},
    tagQuestions: [],
    me: null,
    userDetails: {},
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    getMe: (state) => state.me,
    getWeekGoals: (state) => state
      .user?.currentRewardStatus?.currentRewardData?.critereas || [],
    getUserDetails: (state) => state.userDetails,
  },
  mutations: {
    updatePermissionFlags: (state, data) => {
      state.user.permissionsFlags = data;
    },
    setToken: (state, data) => {
      localStorage.setItem('jwt', data);
      state.token = data;
      // eslint-disable-next-line no-unused-expressions
      axios.defaults.headers.Authorization = data;
    },
    setRefreshToken: (state, data) => {
      localStorage.setItem('jwt_refresh_token', data);
      state.refresh_token = data;
    },
    setUser: (state, data) => {
      localStorage.setItem('lang', data.preferredLanguage);
      i18n.global.locale = data.preferredLanguage;
      state.user = data; // these are the permission flags of the user
      state.userDetails = data; // these are the user details from the user model
    },
    setRegisterData: (state, data) => {
      state.registerData = data;
    },
    setRegisterDataValid: (state, data) => {
      state.registerDataValid = data;
    },
    setPasswordResetRequested: (state, data) => {
      state.passwordResetRequested = data;
    },
    setRegistrationSuccessful: (state, data) => {
      state.registrationSuccessful = data;
    },
    setNewPasswordSetSuccesful: (state, data) => {
      state.newPasswordSetSuccesful = data;
    },
    setLanguage: (state, lang) => {
      state.lang = lang;
    },
    setTags: (state, tags) => {
      if (Array.isArray(tags)) {
        state.unprotectedTags = tags.filter((el) => !el.protected);
        state.protectedTags = tags.filter((el) => el.protected);
      }
    },
    toggleTagSelection: (state, tagId) => {
      const indexToDelete = state.selectedTags.indexOf(tagId);
      if (indexToDelete > -1) {
        state.selectedTags.splice(indexToDelete, 1);
      } else {
        state.selectedTags.push(tagId);
      }
    },
    setTagQuestions: (state, tagQuestions) => {
      if (Array.isArray(tagQuestions)) {
        state.tagQuestions = tagQuestions;
      }
    },
    setTagQuestionAnswer: (state, parameters) => {
      const {
        tagQuestion,
        tagId,
      } = parameters;
      state.tagQuestonsAnswers[tagQuestion._id] = tagId;
    },
    setMe: (state, data) => {
      state.me = data;
    },
    setSelectedTags: (state, data) => {
      state.selectedTags = data;
    },
    removeTagQuestionAnswers: (state) => {
      state.tagQuestonsAnswers = {};
    },
    removeUnprotectedTags: (state) => {
      state.unprotectedTags = [];
    },
    removeProtectedTags: (state) => {
      state.protectedTags = [];
    },
    deleteToken: (state) => {
      localStorage.removeItem('jwt');
      localStorage.removeItem('jwt_refresh_token');
      state.token = null;
      state.refresh_token = null;
    },
  },
  actions: {
    async login({
      state,
      commit,
      dispatch,
    }, loginData) {
      const { data } = await NetworkAuth.login(loginData);
      axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: data.token,
      };
      localStorage.setItem('user', JSON.stringify(data.user));
      if (state.lang && state.lang !== data.language) {
        dispatch('updateProfile', { preferredLanguage: state.lang });
      }
      commit('setToken', data.token);
      commit('setRefreshToken', data.refresh_token);
      commit('setUser', data.user);
      localStorage.setItem('features', JSON.stringify(data.features));
      commit('setFeatures', data.features);
    },
    async refreshToken({ state, commit }) {
      const { data } = await NetworkAuth.refreshToken(state.refresh_token);
      commit('setToken', data.token);
      commit('setRefreshToken', data.refresh_token);
    },
    async validateRegister({ commit }, registerData) {
      await NetworkAuth.validateRegisterData(registerData);
      commit('setRegisterData', registerData);
      commit('setRegisterDataValid', true);
    },
    async registerUser({ commit }, registerData) {
      await NetworkAuth.registerUser(registerData);
      commit('setRegistrationSuccessful', true);
      commit('setRegisterData', null);
      commit('setRegisterDataValid', false);
      commit('setSelectedTags', []);
      commit('setSelectedTeams', []);
      commit('setTeams', []);
      commit('setTagQuestions', []);
      commit('removeTagQuestionAnswers');
      commit('removeUnprotectedTags');
      commit('removeProtectedTags');
    },
    async getTags({ commit }) {
      const { data } = await NetworkAuth.getTags();
      commit('setTags', data.data);
    },
    async getTagQuestions({ commit }) {
      const { data } = await NetworkAuth.getTagQuestions();
      commit('setTagQuestions', data.data);
    },
    async getMe({ commit }) {
      const { data } = await NetworkAuth.getMe();
      commit('setMe', data.data);
    },
    async requestPasswordReset({ commit }, data) {
      await NetworkAuth.requestPasswordReset(data);
      commit('setPasswordResetRequested', true);
    },
    async setNewPassword({ commit }, data) {
      await NetworkAuth.setNewPassword(data);
      commit('setNewPasswordSetSuccesful', true);
    },
    async activateAccount(_, data) {
      await NetworkAuth.activateAccount(data);
    },
    async updateProfile(_, data) {
      localStorage.setItem('lang', data.preferredLanguage);
      await NetworkAuth.updateProfile(data);
    },
    async uploadProfilePicture(_, base64Image) {
      await NetworkAuth.uploadProfilePicture(base64Image);
    },
    async updatePermissionFlags({ commit }) {
      const { data } = await NetworkAuth.checkIfUserLoggedIn();
      commit('updatePermissionFlags', data.permissionFlags);
    },
    async getUserInfo({ commit, dispatch, state }) {
      const { data } = await NetworkAuth.getUserInfo();
      if (state.lang !== data.preferredLanguage) {
        await dispatch('updateProfile', { preferredLanguage: state.lang });
        commit('setLanguage', data.preferredLanguage);
        const newData = await NetworkAuth.getUserInfo();
        commit('setUser', newData.data.user);
      } else {
        commit('setUser', data.user);
      }
    },
    async logout({ commit }) {
      if (process.env.VUE_APP_KEYCLOAK === 'true') {
        await keycloak.logout({
          redirectUri: process.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URI,
        });
      } else {
        commit('deleteToken');
      }
      localStorage.removeItem('pageFeatures');
    },
  },
};
