import axios from 'axios';

export default class NetworkAdmin {
  static getAdminAssessmentSchemas(config) {
    return axios.get('admin/assessmentSchemas', config);
  }

  static getAdminAssessment(config) {
    return axios.get('admin/assessments', config);
  }

  static getAdminContentElement(config) {
    return axios.get('admin/contentElement', config);
  }

  static getAdminConfigElement(config) {
    return axios.get('admin/configElement', config);
  }

  static getAdminUserElements(config) {
    return axios.get('admin/userElements', config);
  }

  static getAdminTaskElements(config) {
    return axios.get('admin/taskElements', config);
  }

  static async saveAdminAssessmentSchemas(data, config) {
    return axios.post('admin/assessmentSchemas', { data }, config);
  }

  static async saveAdminAssessment(data, config) {
    return axios.post('admin/assessments', { data }, config);
  }

  static async saveAdminConfigElement(data, config) {
    return axios.post('admin/configElement', { data }, config);
  }

  static async saveAdminContentElement(data, config) {
    return axios.post('admin/contentElement', { data }, config);
  }

  static async saveAdminUserElements(data, config) {
    return axios.post('admin/userElements', { data }, config);
  }

  static async saveAdminTaskElements(data, config) {
    return axios.post('admin/taskElements', { data }, config);
  }

  static async saveAdminSettingElements(data, config) {
    return axios.post('admin/settingElements', { data }, config);
  }

  static async deleteUser(data, config) {
    return axios.post('admin/commands/deleteUser', data, config);
  }

  static async postFilteredUsers(data, config) {
    return axios.post('admin/commands/filteredUsers', data, config);
  }

  static async createTask(data, config) {
    return axios.post('admin/commands/createTask', data, config);
  }

  static getDetailedUserInfos(id, config) {
    return axios.get(`user/detailedUserInfo/${id}`, config);
  }

  static async postDownloadUserCSV(data, config) {
    return axios.post('user/downloadUsersCSV', data, config, {
      responseType: 'blob',
    });
  }

  static async getFilterComponentData(config) {
    return axios.get('admin/commands/filterComponentData', config);
  }
}
