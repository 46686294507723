<template>
  <div class="main-container" @click="toggleExtendedCard">
    <div v-if="!travelBuddyMeeting" class="no-travel-buddy">
      <div class="left-content">
        <div class="icon-container">
          <img class="icon" :src= "getProfilePic" alt="image">
        </div>
      </div>
      <div class="right-content">
        <div class="no-travel-buddy-title">{{$t('TravelBuddy.noTravelBuddyText')}}</div>
      </div>
    </div>
    <div  v-else :class="{'card-top-extended' : cardExtended}"
         class="card-container">
      <div v-if="canExtendCard" class="expand-shrink-container">
        <button v-if="!cardExtended">
          <font-awesome-icon
            class="expand-shrink-icon"
            icon="fa-solid fa-up-right-and-down-left-from-center"
          />
        </button>
        <button v-if="cardExtended">
          <font-awesome-icon
            class="expand-shrink-icon"
            icon="fa-solid fa-down-left-and-up-right-to-center"
          />
        </button>
      </div>
      <!----- Card Content ------>
      <div :class="{'card-top-extended' : cardExtended}" class="card-top">
        <div class="top-flex-container">
          <div class="member-container">
            <div class="member-photo-container">
              <img :src="getProfilePic" alt="" class="photo">
            </div>
          </div>
          <div class="travel-buddy-info-container">
            <div class="travel-buddy-span-container">
              <span>{{ $t('TravelBuddy.travelBuddy') }}</span>
              <span class="name">{{travelBuddyMeeting.teammateFull}}</span>
              <span class="meetingUpdateSpan" v-if="meetingStatusSelected === 'skipped'">
                {{ $t('TravelBuddy.userMarkedMeetingAsSkipped') }}
              </span>
              <span class="meetingUpdateSpan" v-if="meetingStatusSelected === 'completed'">
                {{ $t('TravelBuddy.userMarkedMeetingAsCompleted') }}
              </span>
            </div>
            <div v-if="showFeedbackButton" class="progress-bar-container">
              <button type="button" class="grid-button-submit"
                  @click.prevent="markMeeting('completed')">
                {{ $t('TravelBuddyFeedbackOverlayComponent.provideFeedback') }}
              </button>
            </div>
            <div v-else class="progress-bar-container">
              <div class="progress-bar" :style="progress">
                <div class="tasks-count">
                  {{ getProgressBarText }}</div>
              </div>
              <div class="mark-goal-container"
                   :class="{'mark-goal-container-hidden': !cardExtended}">
                <span id="markAsDone" @click.stop="markMeeting('completed')">
                  {{ $t('TravelBuddy.markAsDone') }}</span>
                <span id="skip" @click.stop="markMeeting('skipped')">
                  {{ $t('TravelBuddy.skip')}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- extended card container -->
        <div :class="{'card-extended' : cardExtended}" class="card-bottom">
          <div class="plan-meeting-container">
            <div>
              <i> {{ $t('TravelBuddy.planMeeting') }}</i>
            </div>
          </div>
          <h4>Agenda</h4>
          <div class="agenda">
            <div class="agenda-item">
              <div class="icon-wrapper">
                <font-awesome-icon class="stopwatch-icon" icon="fa-stopwatch"/>
              </div>
              <span class="time-span">5 min</span>
              <span>
                {{ $t('TravelBuddy.meetingArrival') }}
              </span>
            </div>
            <div class="agenda-item">
              <div class="icon-wrapper">
                <font-awesome-icon class="stopwatch-icon" icon="fa-stopwatch"/>
              </div>
              <span class="time-span">10 min</span>
              <span>
                {{ $t('TravelBuddy.meetingQ1') }}
                <div class="agenda-item-guide-container">
                  <font-awesome-icon class="caret-icon" icon="fa-caret-right"/>
                  <span>{{ $t('TravelBuddy.meetingQ1Tip') }}</span>
                </div>
              </span>
            </div>
            <div class="agenda-item">
              <div class="icon-wrapper">
                <font-awesome-icon class="stopwatch-icon" icon="fa-stopwatch"/>
              </div>
              <span class="time-span">10 min</span>
              <span>
                {{ $t('TravelBuddy.meetingQ2') }}
                <div class="agenda-item-guide-container">
                  <font-awesome-icon class="caret-icon" icon="fa-caret-right"/>
                  <span>{{ $t('TravelBuddy.meetingQ2Tip') }}</span>
                </div>
              </span>
            </div>
            <div class="agenda-item">
              <div class="icon-wrapper">
                <font-awesome-icon class="stopwatch-icon" icon="fa-stopwatch"/>
              </div>
              <span class="time-span">5 min</span>
              <span>
                {{ $t('TravelBuddy.wrapUp') }}
              </span>
          </div>
        </div>
      </div>
    </div>
    <frontend-gated-component
      value="travelBuddy"
    />
  </div>
</div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FrontendGatedComponent from '../Shared/FrontendGated/FrontendGatedComponent.vue';

export default {
  name: 'TravelBuddyCardComponent',
  components: {
    FontAwesomeIcon,
    FrontendGatedComponent,
  },
  data() {
    return {
      cardExtended: false,
      refreshValue: this.$store.state.content.refreshValue,
    };
  },
  methods: {
    toggleExtendedCard() {
      if (this.canExtendCard) {
        this.cardExtended = !this.cardExtended;
      }
    },
    async markMeeting(status) {
      try {
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'travelBuddyFeedback',
        });
        this.toggleExtendedCard();
        this.$store.commit('setTravelBuddyMeetingStatus', status);
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'error',
          type: 'error',
        });
      }
    },
  },
  computed: {
    meetingStatusSelected() {
      return this.$store.getters.getTravelBuddyMeetingStatus;
    },
    travelBuddyMeeting() {
      const travelBuddyMeetingInfo = this.$store.getters.getTravelBuddyMeetingInfo;
      if (travelBuddyMeetingInfo.meetingThisWeek) {
        return travelBuddyMeetingInfo;
      }
      return false;
    },
    getRefreshValue() {
      return this.$store.getters.getRefreshValue;
    },
    getProfilePic() {
      // eslint-disable-next-line global-require
      if (this.travelBuddyMeeting?.teammatePhoto) {
        return `${this.travelBuddyMeeting?.teammatePhoto}?_${this.getRefreshValue}`;
      }

      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
    getProgressBarText() {
      if (this.meetingStatusSelected === 'skipped') {
        return `${this.$t('TravelBuddy.skipped')}`;
      }
      if (this.meetingStatusSelected === 'completed') {
        return `${this.$t('TravelBuddy.completed')}`;
      }
      return `${this.$t('TravelBuddy.week')} ${this.travelBuddyMeeting.meetingInfo?.calendarWeek}`;
    },
    progress() {
      if (this.meetingStatusSelected === 'completed') {
        return 'background: var(--color-orange)';
      }
      return 'background: var(--color-light-grey)';
    },
    showFeedbackButton() {
      return this.$store.getters.getTravelBuddyFeedbackText === '' && this.$store.getters.getTravelBuddyMeetingStatus === 'completed';
    },
    canExtendCard() {
      return this.$store.getters.getTravelBuddyMeetingStatus === 'created';
    },
  },
};
</script>
<style scoped>
/*Card Container Container*/
span, p, h4, div, strong {
  font-family: "Poppins", sans-serif;
}
.main-container {
  cursor:pointer;
  position: relative;
  width: 100%;
  height: 100%;
}

.no-travel-buddy {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 40px);
  min-width: 290px;
  height: 150px;
  border-radius: 10px;
  background-color: var(--color-milk);
  color: black;
  font-family: Arial, sans-serif;
  flex: 1;
  margin: 10px;
}

.no-travel-buddy-title {
  position: absolute;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: "Poppins", sans-serif;
}
.left-content {
  width: 100px;
  height: 100%;
}
.icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

.icon {
  width: 95%;
  height: auto;
  z-index: 9;
}
.right-content {
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 10px;
  height: 100%;
  align-self: center;
  justify-content: center;
}
.expand-shrink-container {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 15px;
  height: 15px;
  background-color: var(--color-light);
  z-index: 9999
}
.expand-shrink-icon {
  position: absolute;
  top: 0;
  right: 0;
  transition: 150ms ease-in-out;
  font-size: 14px;
  color: var(--color-dark);
  cursor: pointer;
}

.expand-shrink-icon:hover{
  scale: 1.3;
}
.card-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  min-width: 290px;
  height: 150px;
  z-index: 1;
  border-radius: 10px;
  color: black;
  font-family: Arial, sans-serif;
  flex: 1;
  margin-right: 15px;
  overflow: visible;
}

.card-top {
  border-radius: 10px;
  height: 150px;
  min-width: 100%;
  background-color: var(--color-milk);
}

.top-flex-container {
  display: flex;
  height: 100%;
  flex-direction: row;
  width: 100%;
  position: relative;
}

/*Travel Buddy Photo Container*/
button {
  cursor: pointer;
}

.member-container {
  width: 100px;
  height: 100%;
}

.member-photo-container {
  padding-left: 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100px;
}

.member-photo-container .photo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
}

/*Travel Buddy Info Container*/
.travel-buddy-info-container {
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 10px;
  height: 80%;
  align-self: center;
}

.travel-buddy-span-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}

.travel-buddy-info-container span {
  font-size: 18px;
}

.progress-bar-container{
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  width: 100%;
  white-space: nowrap;
}
.progress-bar {
  position: relative;
  width: 100%;
  height: 2rem;
  border-radius: 50px;
  overflow: hidden;
  background: var(--color-light-grey);
  text-align: center;
  align-self: flex-end;
  min-height: 2rem;
  bottom: -20px;
}

.progress-bar-container span {
  margin-right: 10px;
  font-size: 12px !important;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
}

.progress-bar-container span::after {
  content: '';
  width: 0;
  height: 1px;
  display: block;
  background: var(--color-dark);
  transition: 300ms ease-out;
}
.progress-bar-container span:nth-child(1)::after{
  background-color: var(--color-orange);
}

.progress-bar-container span:hover::after {
  width: 100%;
}

.mark-goal-container{
  position: relative;
  width:max-content;
  margin-top: 5px;
  visibility: visible;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  top: 15px;
  font-size: 14px;
}

.mark-goal-container span {
  font-size: 14px;
  white-space: nowrap;
  padding-left: 5px;
}

.mark-goal-container-hidden{
  visibility: hidden;
}

#markAsDone {
  color: var(--color-orange);
}

.tasks-count {
  display: flex;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

/*Card Extension Container*/
.card-top-extended {
  border-radius: 10px 10px 0 0;
  display: flex;
  height: 150px;
  width: 100%;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
}

.card-bottom {
  position: absolute;
  top: 150px;
  width: 100%;
  display: none;
  background-color: var(--color-milk);
}

.card-extended {
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: max-content;
  width: 100%;
  border-radius: 0 0 10px 10px;
  padding: 15px 0;
  box-shadow: 1px 3px 2px 1px rgba(0, 0, 0, 0.2);
}

.plan-meeting-container {
  display: flex;
  height: min-content;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-extended h4 {
  margin: 10px 0 0 25px;
}

.agenda {
  width: 100%;
  padding-left: 25px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.agenda-item {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  min-height: 20px;
  max-height: fit-content;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 10px;
}

.stopwatch-icon {
  color: var(--color-orange);
  margin-right: 10px;
}
.caret-icon {
  margin: 0 5px;
  color: var(--color-dark)
}
.agenda-item span.time-span {
  margin-right: 20px;
  width: 50px;
  white-space: nowrap;
}

.agenda-item-guide-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plan-meeting-container div {
  width: fit-content;
  padding: 0 5% 0 10%;
}

.meetingUpdateSpan {
  color: var(--color-disabled);
  font-size: 10px !important;
}

button.grid-button-submit {
  cursor: pointer;
  text-decoration: none;
  color: #1B1B1B;                               /*color: white;*/
  background: var(--color-orange);        /*border: 1px solid #1E2019;*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 2rem;
}

/*----------Media Queries-----------*/
@media (max-width: 1024px) {
  .card-container {
    margin: 10px 10px;
  }

  .card-container.card-top {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .card-container {
    margin-bottom: 10px;
  }

  .travel-buddy-info-container span, .progress-bar .tasks-count {
    font-size: 16px;
  }

  .card-container .card-top {
    font-size: 16px;
  }
}

@media (min-width: 1025px) {
  .card-container {
    margin-bottom: 10px;
  }
  .no-travel-buddy {
    margin: 0 0 10px 0;
  }
}

@media (max-width: 1440px) {
  .card-container {
    max-width: calc(100% - 40px);
  }
}

@media (min-width: 1440px) {
  .card-container {
    min-width: 320px;
  }

  .card-top-extended {
    display: flex;
    width: 350px;
  }
}

@media screen and (min-width: 1920px) {
  .main-container {
    width: 400px;
  }

  .card-top-extended {
    display: flex;
    width: 550px;
  }
}

@media screen and (max-width: 521px) {
  .card-container {
    margin: 0;
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .top-flex-container {
    min-width: 100%;
    padding: 0 5px;
  }
  .no-travel-buddy {
    min-width: 100%;
    margin: 5px 0;
  }
  .card-container {
    margin: 5px 0;
    min-width: 100%;
  }
  @media (max-width: 400px) {
    .card-container {
      margin: 5px 0;
      min-width: 100%;
    }
  }
  @media (max-width: 400px) {

    .travel-buddy-span-container span {
      white-space: normal;
      width: 100%;
    }

    .mark-goal-container {
      flex-wrap: nowrap;
    }
  }
  @media (max-width: 300px) {
    .travel-buddy-info-container {
      max-width: 52vw;
    }
    .mark-goal-container span {
      font-size: 10px !important;
      margin: 0;
    }
  }
}
</style>
