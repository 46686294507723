<template>
  <div v-if="getKssHistory" class="kss-champdata">
    <div class="headline">
      <h1>{{ $t('DashboardKssHistoryComponent.kssChampionDataTitle') }}</h1>
    </div>
    <div class="champData-content">
      <div class="champData-wrapper">
        <div class="champData-value">
          <h1 class="champData-number">{{ getKssHistory.kssChoosen }}x</h1>
          <div class="icon-container">
            <font-awesome-icon class="champData-icon-heart" icon="fa-regular fa-heart"/>
            <font-awesome-icon class="champData-icon-thumbs" icon="fa-thumbs-up"/>
          </div>
          <p class="champData-text">{{ $t('DashboardKssHistoryComponent.positiveFeedback') }}</p>
        </div>
        <div class="champData-value">
          <h1 class="champData-number">{{ getKssHistory.kssTrackerCreated }}x</h1>
          <div class="icon-container">
            <font-awesome-icon class="champData-icon" icon="fa-solid fa-circle-plus"/>
          </div>
          <p class="champData-text">{{ $t('DashboardKssHistoryComponent.kssCreated') }}</p>
        </div>
        <div class="champData-value">
          <h1 class="champData-number">{{ getKssHistory.kssTrackerSubmitted }}x</h1>
          <div class="icon-container">
            <font-awesome-icon class="champData-icon" icon="fa-regular fa-circle-check"/>
          </div>
          <p class="champData-text">{{ $t('DashboardKssHistoryComponent.kssSubmtted') }}</p>
        </div>
      </div>
    </div>
    <frontend-gated-component value="own_collaborationTrend" />
  </div>
</template>

<script>
import FrontendGatedComponent from '../Shared/FrontendGated/FrontendGatedComponent.vue';

export default {
  name: 'DashboardKssHistoryComponent',
  components: {
    FrontendGatedComponent,
  },
  computed: {
    getKssHistory() {
      return this.$store.getters.getKssHistory;
    },
  },
  async mounted() {
    await this.$store.dispatch('getKssHistory');
  },
};
</script>

<style scoped>
.kss-champdata {
  position: relative;
  display: block;
  box-sizing: border-box;
  background: var(--color-light);
  padding: var(--card-padding);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.kss-champdata {
  margin-top: 20px;
}

.champData-content {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.champData-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.champData-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  margin-top: 10px;
}

.champData-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 5px;
  font-size: 50px;
  line-height: 100%;
  padding: 0 2px;
  min-width: 80px;
}

.champData-icon {
  height: 30px;
  width: 30px;
}

.icon-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 5px;
  position: relative;
  min-width: 50px;
  height: 50px;
}

.champData-icon-thumbs {
  position: absolute;
  top: 17px;
  left: 18px;
  width: 15px;
  height: 15px;
}

.champData-icon-heart {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 521px){
  .kss-champdata h1 {
    font-size: 23px;
  }
}
</style>
