import store from '@/store';

export default class FeatureHelper {
  static pageFeatures(route) {
    if (store.state.feature.features) {
      const pageFeatures = store.getters.getFeatures?.filter(
        (featureItem) => {
          const routeWithoutParam = String(route).split('?')[0];
          return routeWithoutParam === featureItem.route;
        },
      )[0];
      store.commit('setPageFeatures', pageFeatures ?? []);
    }
  }

  static featureStatus(name) {
    const pageSubFeature = store.getters.getPageFeatures?.subFeatures?.filter(
      (subFeature) => subFeature.name === name,
    );

    return pageSubFeature?.length > 0 && pageSubFeature[0].status;
  }

  static IsAllSubFeatureDeactivated(route) {
    if (store.state.feature.features) {
      const pageFeatures = store.getters.getFeatures?.filter(
        (featureItem) => {
          const routeWithoutParam = String(route).split('?')[0];
          return routeWithoutParam === featureItem.route;
        },
      )[0];

      return !pageFeatures?.subFeatures?.map(
        (subFeature) => subFeature.status === -1,
      ).includes(false);
    } return false;
  }

  static getGatedStatusByPage(feature) {
    if (store.state.auth.user.permissionsFlags.admin) {
      return 'd-none';
    }
    const pageSubfeature = store.getters.getPageFeatures?.subFeatures?.filter(
      (subFeature) => subFeature.name === feature,
    )[0];

    const value = pageSubfeature ? pageSubfeature.status : 1;

    switch (value) {
      case 0:
        return 'gated';
      default:
        return 'd-none';
    }
  }

  static getGatedStatus(feature) {
    let status = 'd-none';
    store.getters.getFeatures.forEach((featureItem) => {
      const targetedFeature = featureItem?.subFeatures?.filter(
        (subFeature) => subFeature.name === feature,
      );
      if (targetedFeature?.[0]?.status === 0) status = 'gated';
    });

    return status;
  }
}
