<template>
  <breadcrumbs-component :breadCrumbs="dashboardBreadCrumbs"/>
  <div class="team-dashboard-container">
    <div class="wrapper">
      <div class="box">
        <div class="tabs-container">
          <div class="tab" v-if="getTeamOmindSetEnabled">
             <input
                  type="radio" checked="checked" name="box" id="oim_box"
                  @click="changeTab('oim')">
            <label for="oim_box" class="navlabel"
                  :class="{ 'selected-tab': isOIMSelected}">OMindset</label>
          </div>
          <div class="tab" v-if="getTeamCollaborationTrend">
            <input type="radio" name="box" id="kss_box"
                  @click="changeTab('kss')">
            <label for="kss_box" class="navlabel"
                  :class="{ 'selected-tab': isKSSSelected}">
                  {{ $t('DashboardComponent.collaborationTrend') }}
                </label>
          </div>
          <div class="tab" v-if="getTeamMoodOMat">
            <input type="radio" name="box" id="moodomat_box"
                  @click="changeTab('moodomat')">
            <label for="moodomat_box" class="navlabel"
                  :class="{ 'selected-tab': isMoodomatSelected}">Mood-O-Mat</label>
          </div>
        </div>
        <div v-if="selectedTab === 'oim' && getTeamOmindSetEnabled" class="content">
          <div class="oim">
            <div class="headline">
              <h1 id="txt_currentOIM">{{ $t('TeamDashboardComponent.omindsetTeam') }}</h1>
              <div class="read-more">
                <router-link to="knowledge-database">
                  <i>
                    <font-awesome-icon icon="fa-circle-question"/>
                  </i>
                </router-link>
              </div>
            </div>
            <div class="selection-row">
              <dashboard-team-selection-component class="team-selection"/>
              <dashboard-date-select-component class="team-selection"/>
            </div>
            <div class="diagrams-row">
              <div class="diagram">
                <div class="canvas-container">
                  <bar v-if="getOimTeamPlotData"
                      width="500"
                      height="500"
                      :data="getOimTeamPlotData.data"
                      :options="getOimTeamPlotData.options"
                      id="oi_chart"/>
                  <p v-else>
                    {{ getErrorMessage }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <frontend-gated-component value="team_oMindSet" />
        </div>
        <div v-if="selectedTab === 'kss' && getTeamCollaborationTrend" class="content">
          <div class="kss">
            <div class="headline">
              <h1 id="txt_kssHistory">{{ $t('TeamDashboardComponent.kssTeam') }}</h1>
            </div>
            <dashboard-team-selection-component class="team-selection"/>
            <div class="diagram">
              <div class="canvas-container">
                <Line v-if="getKssTeamPlotData" width="500" height="300"
                      :options="getKssTeamPlotData.options"
                      :data="getKssTeamPlotData.data"/>
                <p v-else>
                  {{ getErrorMessage }}
                </p>
              </div>
            </div>
          </div>
          <frontend-gated-component value="team_collaborationTrend" />
        </div>
        <div v-if="selectedTab === 'moodomat' && getTeamMoodOMat" class="content">
          <div class="moodomat">
            <div class="headline">
              <h1>{{ $t('TeamDashboardComponent.moodomat') }}</h1>
            </div>
            <dashboard-team-selection-component class="team-selection"/>
            <div class="diagram">
              <div class="canvas-container">
                <Line v-if="getMoodomatTeamPlotData" width="500" height="300"
                      :options="getMoodomatTeamPlotData.options"
                      :data="getMoodomatTeamPlotData.data"/>
                <p v-else>
                  {{ getErrorMessage }}
                </p>
              </div>
            </div>
            <TeamDashboardMoodomatComponent/>
          </div>
          <frontend-gated-component value="team_moodOMat" />
        </div>
      </div>
    </div>
  </div>
  <ProvideFeedbackComponent></ProvideFeedbackComponent>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import { Bar, Line } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  TimeScale,
  Filler,
} from 'chart.js';
import DashboardTeamSelectionComponent
  from '@/components/Home/DashboardComponent/DashboardTeamSelectionComponent.vue';
import DashboardDateSelectComponent
  from '@/components/Home/DashboardComponent/DashboardDateSelectComponent.vue';
import TeamDashboardMoodomatComponent
  from '@/components/Home/DashboardComponent/TeamDashboardMoodomatComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';
import FrontendGatedComponent from '@/components/Home/Shared/FrontendGated/FrontendGatedComponent.vue';
import FeatureHelper from '@/helpers/FeatureHelper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  TimeScale,
  Filler,
);

export default {
  name: 'TeamDashboardComponent',
  props: {
    tab: {
      type: String,
      default: 'oim',
    },
  },
  components: {
    DashboardDateSelectComponent,
    DashboardTeamSelectionComponent,
    BreadcrumbsComponent,
    Bar,
    Line,
    TeamDashboardMoodomatComponent,
    ProvideFeedbackComponent,
    FrontendGatedComponent,
  },
  data() {
    return {
      oimTeamPlotLoaded: false,
      kssTeamPlotLoaded: false,
      moodomatTeamPlotLoaded: false,
      selectedTab: this.tab,
      dashboardBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/team-dashboard',
          isActive: true,
          name: this.$t('BreadCrumbs.teamDashboard'),
        },
      ],
    };
  },
  computed: {
    isMoodomatSelected() {
      return this.selectedTab === 'moodomat';
    },
    isKSSSelected() {
      return this.selectedTab === 'kss';
    },
    isOIMSelected() {
      return this.selectedTab === 'oim';
    },
    getOimTeamPlotData() {
      return this.$store.getters.getOimTeamPlotData;
    },
    getKssTeamPlotData() {
      return this.$store.getters.getKssTeamPlotData;
    },
    getMoodomatTeamPlotData() {
      return this.$store.getters.getMoodomatTeamPlotData;
    },
    lang() {
      return this.$i18n.locale;
    },
    getKssTeamPlotLoaded() {
      return this.kssTeamPlotLoaded;
    },
    getOimTeamPlotLoaded() {
      return this.oimTeamPlotLoaded;
    },
    getMoodomatTeamPlotLoaded() {
      return this.moodomatTeamPlotLoaded;
    },
    getErrorMessage() {
      return this.$store.getters.getIsDashboardLoading
        ? this.$t('TeamDashboardComponent.loading')
        : this.$t('TeamDashboardComponent.notEnoughDataAvailable');
    },
    getTeamOmindSetEnabled() {
      return this.featureStatus('team_oMindSet') !== -1;
    },
    getTeamCollaborationTrend() {
      return this.featureStatus('team_collaborationTrend') !== -1;
    },
    getTeamMoodOMat() {
      return this.featureStatus('team_moodOMat') !== -1;
    }
  },
  methods: {
    isChecked(toCompare) {
      if (toCompare.isChecked === false) {
        return 'item checked';
      }
      return 'item';
    },
    changeTab(tabName) {
      this.selectedTab = tabName;
    },
    featureStatus(name) {
      return FeatureHelper.featureStatus(name);
    },
  },
  async mounted() {
    try {
      this.$store.commit('setIsDashboardLoading', true);
      await this.$store.dispatch('getTeamDashboard');
      this.$store.commit('setIsDashboardLoading', false);
      if (this.getOimTeamPlotData) {
        this.oimTeamPlotLoaded = true;
      }
      if (this.getKssTeamPlotData && this.featureStatus('team_collaborationTrend')) {
        this.kssTeamPlotLoaded = true;
      }

      if (this.getMoodomatTeamPlotData && this.featureStatus('team_moodOMat')) {
        this.moodomatTeamPlotLoaded = true;
      }
    } catch (error) {
      this.$store.commit('setIsDashboardLoading', false);
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: error.response?.data?.message,
        type: 'error',
      });
    }
  },
  unmounted() {
    this.$store.commit('resetPlotData');
  },
};
</script>

<style scoped>

/*------------------ TABSYSTEM OIM > KSS -------------------*/
.team-dashboard-container {
  margin: 0 auto;
  position: relative;
}

.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
}

.selection-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}

.team-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.diagrams-row {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.box {
  position: relative;
}

.tab {
  position: relative;
}

.tabs-container {
  display: flex;
  flex-wrap: nowrap;
}

.box .navlabel {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  background-color: var(--color-milk);
  border-radius: 10px;
  margin: 4px 8px 10px;
  flex: 1;
}

.box .content {
  position: relative;
  padding: 0 0 20px;
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.box input[type="radio"] {
  display: none;
}

.navlabel.selected-tab {
  color: var(--color-orange);
  border: 1px solid var(--color-orange);
}

.box .content .oim {
  display: block;
  box-sizing: border-box;
  background: var(--color-light);
  padding: var(--card-padding);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.box .content .kss {
  display: block;
  box-sizing: border-box;
  background: var(--color-light);
  padding: var(--card-padding);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.box .content .moodomat {
  display: block;
  box-sizing: border-box;
  background: var(--color-light);
  padding: var(--card-padding);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

/*------------------ HEADLINE -------------------*/
.headline {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
}

.headline .read-more {
  font-size: 21px;
}

.headline .read-more a {
  color: var(--color-disabled);
}

.headline .read-more a:hover {
  color: var(--color-orange);
}

/*------------------ DIAGRAMS -------------------*/
.diagram {
  display: block;
  position: relative;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 30px 30px 20px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
}

.canvas-container {
  margin: 0.5rem auto;
  display: block;
  width: 100%;
  aspect-ratio: 4/3;
}
/*use plotData_helper.ts on the backend to style chartJS components. see documentation for more */
.canvas-container canvas {
  width: 100%;
  height: 100%;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 521px) {
  .box .content .kss #txt_kssHistory, #txt_moodomat {
    font-size: 23px;
  }
  .box .navlabel {
    margin: 16px 6px;
    padding: 16px 16px;
  }

  .canvas-container {
    min-height: 300px;
    height: auto;
  }

  .diagram {
    padding: 10px 10px 20px 10px;
    width: 93vw;
  }
  .oim .headline h1{
    font-size: 23px;
  }
  canvas {
    min-height: 350px;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .box .navlabel {
    padding: 16px 35px;
    margin: 16px 6px;
  }
  .canvas-container {
    min-height: 300px;
    height: auto;
  }
  .diagram {
    padding: 16px 0;
    width: 93%;
  }

}

@media (min-width: 769px) and (max-width: 1024px) {
  .box .navlabel {
    padding: 16px 35px;
    margin: 16px 6px;
  }

  .canvas-container {
    height: 600px;
    aspect-ratio: 4/3;
  }
  iframe {
    width: 80vw;
  }
  .diagram {
    padding: 0;
    width: 93%;
  }

}

@media (min-width: 1249px) {
  .diagram {
    padding: 0;
    width: 93%;
  }
  .canvas-container {
    height: 600px;
    aspect-ratio: 4/3;
  }

  iframe {
    width: 90%;
  }
}
</style>
