<template>
  <div class="dropdown-container">
    <div class="dropdown-component-wrapper">
      <div class="dropdown-component">
          <font-awesome-icon
            v-if="!loadingDownloadCSV"
            class="dropdown-icon"
            icon="fa-solid fa-cloud-arrow-down"
          />
          <img v-else class="dropdown-icon" src="@/assets/loadingSpinner.svg" alt="txt" />
        <p>{{ $t("UserAdministrationComponent.options.downloadCSV") }}</p>
      </div>
      <frontend-gated-component value="downloadCSVForUser" />
    </div>
    <div class="dropdown-options-container">
      <div class="dropdown-options">
        <div
          class="dropdown-option"
          :class="{ disabled: Object.keys(getCheckedUsers).length === 0 }"
        >
          <div class="dropdown-title" @click="downloadSelectedUsersCSV">
            <p>{{ $t("UserAdministrationComponent.downloadCSV.downloadSelected") }}</p>
          </div>
        </div>

        <div class="dropdown-option" @click="downloadAllUsersCSV">
          <div class="dropdown-title">
            <p>{{ $t("UserAdministrationComponent.downloadCSV.downloadAll") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FrontendGatedComponent from '@/components/Home/Shared/FrontendGated/FrontendGatedComponent.vue';

export default {
  name: 'UserAdministrationDownloadCSVComponent',
  components: {
    FrontendGatedComponent,
  },
  emits: ['downloadSelectedUsersCSV', 'downloadAllUsersCSV'],
  props: {
    getCheckedUsers: {
      type: Object,
      default: () => ({}),
    },
    loadingDownloadCSV: {
      type: Boolean,
    },
  },
  methods: {
    downloadAllUsersCSV() {
      this.$emit('downloadAllUsersCSV');
    },
    downloadSelectedUsersCSV() {
      if (Object.keys(this.getCheckedUsers).length !== 0) {
        this.$emit('downloadSelectedUsersCSV');
      }
    },
  },
};
</script>

<style scoped>
.dropdown-picks-element > p {
  padding: 0px;
}

.dropdown-name,
.dropdown-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid gray;
  align-items: center;
  padding: 0px var(--card-padding) 0px var(--card-padding);
}

.dropdown-option {
  color: black;
  cursor: pointer;
}

.dropdown-option:hover {
  color: white;
  background-color: var(--color-orange);
}

.dropdown-picks {
  background-color: peachpuff;
  max-height: 180px;
  overflow-y: auto;
}

.dropdown-picks-element {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  display: block;
  margin: 0.5rem;

  padding: 10px 35px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.delete-button {
  color: var(--color-orange);
  cursor: pointer;
}

/*------------------- CHECKBOX -----------------------*/

.checkbox-icon-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 22px;
}

.unchecked-icon {
  font-size: 22px;
}

/*------------ FILTER DROPDOWN ---------------*/

.dropdown-component-wrapper {
  position: relative;
  margin: 5px 0.8rem;
}

.dropdown-component {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 0.5rem;
  border-radius: var(--card-border-radius);
  background-color: var(--color-light);
}

.dropdown-icon {
  margin-right: 7px;
  height: 20px;
  width: 27px;
}

.dropdown-container {
  position: relative;
}

.dropdown-options-container {
  display: none;
  position: absolute;
  top: 92%;
  right: 0;
  z-index: 1;
  padding-top: 5px;
}

.dropdown-options {
  width: 300px;
  max-height: 500px;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: var(--card-border-radius);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
}

.disabled {
  color: grey;
  cursor: not-allowed;
}

.disabled:hover {
  color: white;
  background-color: gray;
  cursor: not-allowed;
}

.dropdown-component:hover + .dropdown-options-container,
.dropdown-options-container:hover {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 621px) {
  .dropdown-options-container {
    right: auto;
    left: 0;
  }
}

@media screen and (max-width: 421px) {
  .dropdown-options-container {
    left: auto;
    right: 0;
  }

  .dropdown-options {
    width: 350px;
  }
}

@media screen and (max-width: 392px) {
  .dropdown-options-container {
    right: auto;
    left: 0;
  }
}
</style>
