const FeatureRoute = {
  mngDashboard: '/management-dashboard',
  '360mindSet': '/360-mindset',
  workshops: '/workshops',
  home: '/',
  developmentTip: '/devTip',
  kssTracker: '/kss-tracker',
  personalGoals: '/personal-goals',
  teamAdministration: '/team-administration',
  userAdministration: '/user-administration',
  ownResults: '/dashboard',
  knowledgeDatabase: '/knowledge-database',
  teamResults: '/team-dashboard',
  oimIntro: '/oim-intro',
};

export default FeatureRoute;
