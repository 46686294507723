<template>
  <div class="icon-container-wrapper">
    <div v-if="getDevTippsEnabled" class="icon-container" :class="{ 'hovered': isHovered }"
        @click="toggleShowDevTipps"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @focusin="isHovered = true"
        @focusout="isHovered = false">
      <font-awesome-icon :class="{ 'glowing': hasNew }" icon="fa-regular fa-lightbulb"/>
      <font-awesome-icon v-if="hasNew" class="circle-icon" icon="fa-circle"/>
    </div>
    <frontend-gated-component
      value="developmentTip"
      :should-gate="getDevelopmentTipGatedStatus"
    />
  </div>
  <dev-tip-item-container-component v-if="getShowDevTipps"/>
</template>

<script>
import DevTipItemContainerComponent
  from '@/components/Home/Shared/DevTip/DevTipItemContainerComponent.vue';
import FrontendGatedComponent from '../FrontendGated/FrontendGatedComponent.vue';
import FeatureHelper from '@/helpers/FeatureHelper';
import FeatureRoute from '@/utils/features';

export default {
  name: 'DevTipIconComponent',
  components: {
    DevTipItemContainerComponent,
    FrontendGatedComponent,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    getShowDevTipps() {
      return this.$store.getters.getShowDevTipps;
    },
    getDevTippsEnabled() {
      return (
        this.getPermissions.admin
        || !FeatureHelper.IsAllSubFeatureDeactivated(FeatureRoute.developmentTip)
      );
    },
    hasNew() {
      return this.$store.getters.getHasNewDevTipps;
    },
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
    getDevelopmentTipGatedStatus() {
      if (this.getPermissions.admin) return false;
      return FeatureHelper.getGatedStatus('developmentTip') === 'gated';
    },
  },
  methods: {
    toggleShowDevTipps() {
      this.$store.commit('toggleShowDevTipps');
    },
  },
};
</script>

<style scoped>
.icon-container-wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  margin-right: 30px;
}
.icon-container {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background: var(--color-milk);
}

.icon-container.hovered {
  font-weight: 600;
  cursor: pointer;
}

.fa-lightbulb {
  font-size: 2.7rem;
  position: relative;
  z-index: 0;
}

.glowing {
  color: var(--color-orange);
}

.checked-icon {
  color: var(--color-orange);
  font-size: 40px;
}

.unchecked-icon {
  font-size: 40px;
}

.circle-icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.3rem;
  color: var(--color-orange);
  z-index: 1;
}

.icon-container.hovered :nth-child(2){
  top: -1px;
  left: -1px;
}
</style>
