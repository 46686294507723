import NetworkAdmin from '@/helpers/networking/NetworkAdmin';
import NetworkContent from '@/helpers/networking/NetworkContent';

const checkLocalStorageValues = (key) => {
  let parsedValue = '[]';

  try {
    const value = localStorage.getItem(key);
    parsedValue = JSON.parse(value ?? '[]');
  } catch (error) {
    parsedValue = '[]';
  }

  return parsedValue;
};

export default {
  state: {
    features: checkLocalStorageValues('features'),
    pageFeatures: checkLocalStorageValues('pageFeatures'),
  },
  getters: {
    getFeatures: (state) => state.features,
    getPageFeatures: (state) => state.pageFeatures,
  },
  mutations: {
    setFeatures(state, features) {
      state.features = features;
      localStorage.setItem('features', JSON.stringify(features));
    },
    setPageFeatures(state, features) {
      state.pageFeatures = features;
      localStorage.setItem('pageFeatures', JSON.stringify(features));
    },
  },
  actions: {
    async fetchUpdatedFeatures({ commit }, payload) {
      const { data } = await NetworkAdmin.saveAdminSettingElements(payload);
      localStorage.setItem('features', JSON.stringify(data.data));
      commit('setFeatures', data.data);
    },

    async fetchFeatures({ commit }) {
      const { data } = await NetworkContent.getContentSettingElements();
      localStorage.setItem('features', JSON.stringify(data.data));
      commit('setFeatures', data.data);
    },
  },
};
