<template>
  <canvas id="avgKSSTrendChart" width="100%" style="max-height: 180px"></canvas>
  <div class="chart-no-data" v-if="!hasData">
    {{ $t('mngDashboardComponent.notEnoughDataToDisplay') }}
  </div>
</template>
<script>
import Chart from 'chart.js/auto';

export default {
  name: 'AvgCollaborationChartComponent',
  data() {
    return {
      hasData: false,
      avgKSSTrendChart: null,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // Clean up chart instance if it exists
    if (this.avgKSSTrendChart) {
      this.avgKSSTrendChart.destroy();
    }
  },

  methods: {
    handleResize() {
      if (this.avgKSSTrendChart) {
        this.avgKSSTrendChart.resize();
      }
    },
    avgCollaborationTrendChart(avgKSSTrendData) {
      if (Object.keys(avgKSSTrendData).length === 0) return;
      // Get the chart container element
      const ctx1 = document.getElementById('avgKSSTrendChart');
      if (!ctx1) return;
      // Check if a chart already exists on this canvas
      if (this.avgKSSTrendChart) {
        // Destroy the existing chart
        this.avgKSSTrendChart.destroy();
      }
      ctx1.parentNode.style.width = '100%';
      ctx1.parentNode.style.height = '100%';
      const labels = avgKSSTrendData?.data?.labels ?? [];
      const datasets = avgKSSTrendData?.data?.datasets?.filter(
        (dataset) => dataset !== null && typeof dataset !== 'undefined',
      ) ?? [];
      this.hasData = !!datasets.find((s) => s.data.find((z) => z));
      if (this.hasData === false) return;
      const options = avgKSSTrendData?.options ?? [];
      const percentageInCenter = {
        id: 'percentageInCenter',
        beforeDatasetsDraw(chart) {
          const { ctx, data } = chart;
          ctx.save();
          function textLabel(text, x, y, fontSize, textBaseLine, textAlign) {
            ctx.font = `${fontSize}px sans-serif`;
            ctx.fillStyle = 'black';
            ctx.textBaseLine = textBaseLine;
            ctx.textAlign = textAlign;
            ctx.fillText(text, x, y);
          }
          if (datasets[0].data.length >= 2 && datasets[0].backgroundColor[0] === '#F49718') {
            const xCoor = chart.getDatasetMeta(0)?.data[0]?.x;
            const yCoor = chart.getDatasetMeta(0)?.data[0]?.y;
            const circumference = chart.getDatasetMeta(0).data[0]?.innerRadius;
            // Define the submittedValue and createdValue values
            const submittedValue = data.datasets[0]?.data[0];
            const createdValue = submittedValue + Number(data?.datasets[0]?.data[1]);

            // Calculate the percentage
            const percentage = (submittedValue / createdValue) * 100;

            textLabel(
              createdValue,
              xCoor + circumference + 20,
              yCoor + 20,
              13,
              'top',
              'center' - 100,
            );
            textLabel(`${percentage.toFixed(2)}%`, xCoor, yCoor - 10, 30, 'bottom', 'center');
          } else if (options?.plugins?.tooltip) {
            options.plugins.tooltip.callbacks = {
              label(context) {
                const datas = context.dataset?.data || [];
                const index = context.dataIndex;
                if (index === 0) return `${datas[index].toFixed(2)}%`;
                return `${(datas[0] + datas[index]).toFixed(2)}%`;
              },
            };
            // eslint-disable-next-line max-len
            options.plugins.tooltip.filter = ({ dataset, dataIndex }) => dataset.data.length - 1 !== dataIndex;
          }
        },
      };
      this.avgKSSTrendChart = new Chart(ctx1, {
        type: 'doughnut',
        data: {
          labels, // Populate with your date labels
          datasets, // Assign your datasets
        },
        options,
        plugins: [percentageInCenter],
      });
    },
  },
};
</script>
