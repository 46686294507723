<template>
  <breadcrumbs-component :bread-crumbs="mindset360Breadcrumb"/>
  <div class="omindset-container">
    <div class="wrapper">
      <div class="box">
        <div class="legend">
          <div class="legend-group">
            <div class="legend-box-open"></div>
            <label> {{ $t('Mindset360Display.open') }} </label>
          </div>
          <div class="legend-group">
            <div class="legend-box-submitted"></div>
            <label> {{ $t('Mindset360Display.submitted') }} </label>
          </div>
        </div>
      </div>
      <div class="content" id="task_card_container">
        <mindset360-card-component v-for="mindset in all360Mindsets" :mindset="mindset"
          :key="mindset._id"/>
        <frontend-gated-component value="360mindSet" />
      </div>
    </div>
  </div>
  <provide-feedback-component></provide-feedback-component>
</template>
<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import Mindset360CardComponent from './Mindset360CardComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';
import FrontendGatedComponent from '../Shared/FrontendGated/FrontendGatedComponent.vue';

export default {
  name: 'Mindset360Component',
  components: {
    BreadcrumbsComponent,
    Mindset360CardComponent,
    ProvideFeedbackComponent,
    FrontendGatedComponent,
  },
  data() {
    return {
      searchbar: '',
      mindset360Breadcrumb: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/360-mindset',
          isActive: true,
          name: this.$t('BreadCrumbs.360OMindset'),
        },
      ],
    };
  },
  computed: {
    all360Mindsets() {
      return this.$store.getters.get360Mindset;
    },
  },
  mounted() {
    this.$store.dispatch('get360Mindset').then(() => {}).catch((e) => { console.log(e); });
  },
};
</script>
<style scoped>
.omindset-container {
  margin: 0 auto;
  position: relative;
}
.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
}

.box {
  position: relative;
}

.content {
  position: relative;
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.legend {
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.legend-box-open, .legend-box-submitted {
  width: 60px;
  height: 15px;
  margin-right: 5px;
  border-radius: 8px;
}

.legend-box-open {
  border: 1px solid var(--color-orange);
  background-color: var(--color-orange);
}

.legend-box-submitted {
  border: 1px solid var(--color-light-grey);
  background-color: var(--color-light-grey);
}

.legend-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}

@media  (max-width: 1024px) {
  .box {
    margin: 0 10px;
  }
}
</style>
