<template>
  <breadcrumbs-component :breadCrumbs='breadCrumbs' />
  <div class='mng-feature'>
    <div class='feature-status-container'>
      <div className='feature-container'>
        <div class='title-container'>
          <span class='title'>{{ $t('mngFeatureComponent.feature') }}</span>
        </div>
        <management-feature-rows-component
          class='feature-route-container'
          v-for='feature in getFeatures'
          :feature='feature'
          :key='feature.name'
          @onChangeFeatureValue='onChangeSubFeatureValue'
          @onChangeFeatureAllValue='onChangeFeatureAllValue'
        />
      </div>
      <div class='feature-button'>
        <button @click='updateFeatures' class='button'>
          <p>{{ $t('mngFeatureComponent.updateFeatures') }}</p>
        </button>
      </div>
    </div>
  </div>
  <ProvideFeedbackComponent />
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import ManagementFeatureRowsComponent from '@/components/Home/AdminComponent/AdminSettingComponent/ManagementFeatureRowsComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';

export default {
  name: 'AdminSettingComponent',
  components: {
    BreadcrumbsComponent,
    ProvideFeedbackComponent,
    ManagementFeatureRowsComponent,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      breadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: this.$t('BreadCrumbs.home'),
        },
        {
          goTo: '/admin-setting',
          isActive: true,
          name: this.$t('BreadCrumbs.adminSetting'),
        },
      ],
      settingsData: [],
      selectedTab: 'gatedSetting',
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    getFeatures() {
      return this.$store.getters.getFeatures;
    },
  },
  mounted() {
    this.fetchFeatures();
  },
  methods: {
    changeTab(tabName) {
      this.selectedTab = tabName;
    },
    onChangeSubFeatureValue({ upperFeatureName, subFeatureName, value }) {
      const filterFeature = this.getFeatures?.filter(
        (featureItem) => featureItem.name === upperFeatureName,
      )[0];

      const setSubFeatures = filterFeature.subFeatures.map((subFeature) => ({
        ...subFeature,
        status: subFeature.name === subFeatureName ? value : subFeature.status,
      }));

      const features = this.getFeatures?.map((featureItem) => ({
        ...featureItem,
        subFeatures:
          featureItem.name === upperFeatureName
            ? setSubFeatures
            : featureItem.subFeatures,
      }));

      this.$store.commit('setFeatures', features);
    },
    onChangeFeatureAllValue({ featureName, value }) {
      const filterFeature = this.getFeatures?.filter(
        (featureItem) => featureItem.name === featureName,
      )[0];
      const newSubFeatures = filterFeature.subFeatures.map((subFeature) => ({
        ...subFeature,
        status: value,
      }));
      const newFeature = { ...filterFeature, subFeatures: newSubFeatures };
      const features = this.getFeatures?.map(
        (featureItem) => (featureItem.name === featureName ? newFeature : featureItem),
      );
      this.$store.commit('setFeatures', features);
    },
    async updateFeatures() {
      try {
        const data = this.getFeatures;
        this.$store.dispatch('fetchUpdatedFeatures', data);

        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('mngFeatureComponent.toast.successfulUpdateFeatures'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('mngFeatureComponent.toast.errorUpdateFeatures'),
          type: 'error',
        });
      }
    },
    async fetchFeatures() {
      try {
        this.$store.dispatch('fetchFeatures');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('mngFeatureComponent.toast.errorFetchFeatures'),
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>
.mng-feature {
  font-size: 12px;
  background-color: var(--color-milk);
  padding: 6em;
  border-radius: 2em;
  line-height: 1.5em;
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 0px auto;

  position: relative;
  overflow-y: auto;
}

.button {
  display: block;
  margin-top: 0.5rem;

  padding: 10px 35px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.feature-route-container {
  padding-left: 20px;
  padding-right: 20px;
}

.feature-status-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 30px 0px 30px;
}

.feature-container {
  padding-bottom: 20px;
}

.feature-button {
  display: flex;
  justify-content: center;
}

.title-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid var(--color-orange);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.title {
  font-size: 24px;
  padding-bottom: 10px;
  color: var(--color-orange);
}

input[type='radio'] {
  display: none;
}

@media only screen and (max-width: 800px) {
  .mng-feature {
    padding: 0.25em;
    padding-bottom: 2em;
  }
}

@media only screen and (max-width: 500px) {
  .feature-status-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
